/* @flow */
/* eslint-env browser */

import type { Node } from 'react';

import { Component } from 'react';
import { connect } from 'react-redux';
import { getIsEnterpriseCustomer } from '../../reducers';

import classnames from 'classnames';
import omit from 'lodash/omit';

import styled, { css } from 'styled-components';
import { styleUtils } from 'eyeem-components';

import { isClickInsideElement } from '../../helpers/tools';

import { TEST_FLYOUT } from '../../constants/pageObjectSelectors';

// todo: migrate this away
const mediaOverrides = css`
  .g_media-left {
    margin-right: 16px;

    height: 48px;
  }
  .g_media-body {
    padding-bottom: 2px;
  }
  .g_media-right {
    position: absolute;
    right: 0;
    float: none;

    color: ${styleUtils.fromTheme('colors.blacks.black1')};
  }
`;

const whiteFlyoutTheme = css`
  padding: 16px;
  width: 300px;
  border: 1px solid ${styleUtils.fromTheme('colors.uncategorized.unnamed15')};
  background-color: ${styleUtils.fromTheme('colors.whites.white')};
  box-shadow: 0 2px 4px 2px
    ${styleUtils.fromTheme('colors.uncategorized.unnamed15')};
  color: ${styleUtils.fromTheme('colors.greys.grey3')};
`;

const StyledFlyout = styled.div`
  ${(props) =>
    props.flyoutStyle === 'share'
      ? css`
          right: -14px;
          bottom: 35px;

          padding: 16px 24px 24px 24px;
          background-color: ${styleUtils.fromTheme('colors.blacks.black1')};
          color: ${styleUtils.fromTheme('colors.whites.white')};

          &:before {
            right: 15px;
            bottom: -16px;

            border-width: 16px 16px 0;
            border-color: ${styleUtils.fromTheme(
                'colors.uncategorized.unnamed3'
              )}
              transparent;
          }
          &:after {
            right: 15px;
            bottom: -14px;

            border-width: 16px 16px 0;
            border-color: ${styleUtils.fromTheme('colors.blacks.black1')}
              transparent;
          }
        `
      : ''} ${(props) =>
    props.flyoutStyle === 'lightbox'
      ? css`
          top: 56px;
          right: ${props.isEnterprise ? '70px' : '110px'};
          bottom: auto;
          &:before {
            top: -17px;
            right: 14px;
            bottom: auto;

            border-width: 0 16px 16px;
            border-color: ${styleUtils.fromTheme(
                'colors.uncategorized.unnamed15'
              )}
              transparent;
          }
          &:after {
            top: -16px;
            right: 14px;
            bottom: auto;

            border-width: 0 16px 16px;
            border-color: ${styleUtils.fromTheme('colors.whites.white')}
              transparent;
          }
          ${whiteFlyoutTheme} ${mediaOverrides};
        `
      : ''} ${(props) =>
    props.flyoutStyle === 'cart' || props.flyoutStyle === 'cart-lightbox'
      ? css`
          top: 56px;
          right: -8px;
          bottom: auto;
          &:before {
            top: -17px;
            right: 14px;
            bottom: auto;

            border-width: 0 16px 16px;
            border-color: ${styleUtils.fromTheme(
                'colors.uncategorized.unnamed15'
              )}
              transparent;
          }
          &:after {
            top: -16px;
            right: 14px;
            bottom: auto;

            border-width: 0 16px 16px;
            border-color: ${styleUtils.fromTheme('colors.whites.white')}
              transparent;
          }
          ${whiteFlyoutTheme} ${mediaOverrides};
        `
      : ''}${(props) =>
    props.flyoutStyle === 'toast'
      ? css`
          position: fixed;
          margin: 0;
          bottom: 32px;
          right: 32px;
          &:before,
          &:after {
            display: none;
          }
          ${whiteFlyoutTheme} ${mediaOverrides};
        `
      : ''};
`;

type Props = {
  onCloseFlyout?: Function,
  className?: string,
  children: Node,
  isEnterprise: boolean,
  flyoutStyle: 'share' | 'cart' | 'lightbox' | 'toast' | 'cart-lightbox',
};

class Flyout extends Component<Props> {
  clickOutsideListener = (event: SyntheticInputEvent<HTMLElement>) => {
    const classes = ['g_flyout'];

    if (this.props.onCloseFlyout && !isClickInsideElement(event, classes)) {
      this.props.onCloseFlyout();
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.clickOutsideListener, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutsideListener, false);
  }

  handleInnerClick = (event: SyntheticInputEvent<HTMLElement>) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  render() {
    return (
      <StyledFlyout
        isEnterprise={this.props.isEnterprise}
        {...omit(this.props, 'onCloseFlyout')}
        data-test-id={TEST_FLYOUT}
        className={classnames('g_flyout', this.props.className)}
        onClick={this.handleInnerClick}>
        {this.props.children}
      </StyledFlyout>
    );
  }
}

export default connect((state) => ({
  isEnterprise: getIsEnterpriseCustomer(state),
}))(Flyout);
