import styled from 'styled-components';
import T from '../translate.jsx';

import { ConditionalRevealMixin } from '../../../helpers/styleMixins';

export const StyledDropdownElement = styled(({ hideOn, ...rest }) => (
  <T {...rest} />
))`
  padding: 8px 24px;
  text-transform: uppercase;

  ${ConditionalRevealMixin};
`;

export const StyledConditionalReveal = styled.div`
  ${ConditionalRevealMixin};
`;

export const StyledDropdownList = styled(({ columnCount, ...rest }) => (
  <ul {...rest} />
))`
  ${(props) =>
    props.columnCount ? `column-count: ${props.columnCount};` : ''};
`;

export const getTopForDropdown = (props) => {
  if (props.isInMarketDetails) {
    return '35px';
  }
  if (
    props.isInNav ||
    props.isButtonTrigger ||
    props.isInTab ||
    props.isLanguageSwitch ||
    props.isEnterpriseUserDropdown
  ) {
    return '48px';
  }

  return '90%';
};

export const getWidthForDropdown = (props) => {
  if (props.isNotifications) {
    return '426px';
  }

  if (props.isInMarketDetails) {
    return '186px';
  }

  if (props.columnCount === 2) {
    return '340px';
  }

  if (props.isInNav) {
    if (props.hasDropdownStateArrow) {
      return '240px';
    }

    return '280px';
  }

  if (props.isEnterpriseUserDropdown) {
    return '320px';
  }

  if (props.isInTab) {
    return '342px';
  }

  if (props.isLanguageSwitch) {
    return '150px';
  }

  if (props.isButtonTrigger) {
    return '172px';
  }

  return '240px';
};

export const getLeftForDropdown = (props) => {
  if (props.isNotifications) {
    return '-390px';
  }

  if (props.isInMarketDetails) {
    return '-153px';
  }

  if (props.columnCount === 2) {
    return '-120px';
  }

  if (props.isLanguageSwitch) {
    return '-21px';
  }

  if (props.isInNav) {
    if (props.dropdownCornerCenter) {
      return '-85px';
    }

    return '-246px';
  }

  if (props.isEnterpriseUserDropdown) {
    return '-285px';
  }

  if (props.isInTab) {
    return '-297px';
  }

  if (props.isButtonTrigger) {
    return '-127px';
  }

  return '-100%';
};

export const getArrowLeftPositionForDropdown = (props) => {
  if (props.dropdownCornerTopLeft) {
    return '8px';
  }

  if (props.dropdownCornerCenter && props.hasDropdownStateArrow) {
    return 'calc(50% - 12px)';
  }

  return 'calc(100% - 32px)';
};
