/* @flow */
/* eslint-env browser */

import type { Node } from 'react';

import { useEffect, useState, useRef } from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import t from 'counterpart';
import styled from 'styled-components';

import T from '../translate';

import DropdownChild from './dropdownChild';

import { TriangleMixin } from '../../../helpers/styleMixins';

type Props = {
  trigger: Node,

  // a brighter theme for the trigger (used in navbar)
  triggerThemeBright?: boolean,

  isInNav?: boolean,
  isInTab?: boolean,
  isButtonTrigger: boolean,
  hasDropdownStateArrow?: boolean,
  isEnterpriseUserDropdown?: boolean,
  columnCount?: number,

  dropdownThemeDark?: boolean,
  dropdownCornerTopLeft?: boolean,
  dropdownCornerCenter?: boolean,

  // tracking for the dropdown trigger
  eventType?: string,
  eventName: string,
  eventAction?: string,
  eventLabel?: string,
  eventPosition?: string,

  // the actual dropdown content
  children: Node | string,

  title?: string,

  // Unless disableSEOChildren prop is set, the children
  // are already rendered in the DOM before dropdown
  // is opened (using `display: none`)
  disableSEOChildren?: boolean,

  // passes down test id so it can identified by tests
  // eslint-disable-next-line react/no-unused-prop-types
  'data-test-id'?: string,

  // gets called on statechange and exposes open state
  toggleCallback?: (open: boolean) => void,

  // used for fetchReleasesNeededTask on notifications
  onMouseEnter?: Function,

  isInMarketDetails: boolean,
  isNotifications: boolean,
};

const StyledTrigger = styled(T)`
  cursor: pointer;
  display: flex;
  height: 32px;
`;

const StyledTriggerWrapper = styled.div`
  display: flex;
  align-items: center;

  ${(props) => (props.hasDropdownStateArrow ? TriangleMixin : null)};
`;

const StyledDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

function Dropdown(props: Props) {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClickOutside = (event) => {
    event.stopPropagation();

    if (node.current.contains(event.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleChange = () => setOpen(!open);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    if (props.toggleCallback) {
      props.toggleCallback(open);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const trackingAddition = open
    ? t('dropDownTracking.close')
    : t('dropDownTracking.open');

  return (
    <StyledDropdownWrapper ref={node}>
      <StyledTrigger
        noTranslate
        onMouseEnter={props.onMouseEnter}
        eventType={props.eventType}
        eventName={`${props.eventName}${trackingAddition}`}
        eventAction={props.eventAction}
        eventLabel={props.eventLabel}
        eventPosition={props.eventPosition}
        data-test-id={props['data-test-id']}
        tabIndex={0}
        role="button"
        aria-pressed={open}>
        <StyledTriggerWrapper
          isOpen={open}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setOpen(!open);
          }}
          hasDropdownStateArrow={props.hasDropdownStateArrow}>
          {props.trigger}
        </StyledTriggerWrapper>
      </StyledTrigger>
      <TransitionGroup>
        {open && (
          <CSSTransition
            classNames="transition_dropdown"
            timeout={{ enter: 700, exit: 400 }}>
            <DropdownChild
              hasDropdownStateArrow={props.hasDropdownStateArrow}
              isInMarketDetails={props.isInMarketDetails}
              isInNav={props.isInNav}
              isEnterpriseUserDropdown={props.isEnterpriseUserDropdown}
              isNotifications={props.isNotifications}
              isInTab={props.isInTab}
              columnCount={props.columnCount}
              isButtonTrigger={props.isButtonTrigger}
              dropdownCornerTopLeft={props.dropdownCornerTopLeft}
              dropdownCornerCenter={props.dropdownCornerCenter}
              dropdownThemeDark={props.dropdownThemeDark}
              handleClickOutsideInParent={() => handleChange}>
              {props.children}
            </DropdownChild>
          </CSSTransition>
        )}
      </TransitionGroup>
      {!props.disableSEOChildren && (
        <div style={{ display: 'none' }}>{props.children}</div>
      )}
    </StyledDropdownWrapper>
  );
}

export default Dropdown;
